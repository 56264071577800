import React, { useEffect } from 'react';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
// import { PaymentRequest } from '@stripe/stripe-js';
import { gql, useMutation } from '@apollo/client'

const FINALIZE_ORDER = gql`
mutation FinalizeOrder($address: AddressInput!, $email: String!, $items: [UUID4], $name: String!, $paymentId: String) {
  finalizeOrder(address: $address, email: $email, items: $items, name: $name, paymentId: $paymentId) {
    clientSecret
    id
    paymentId
  }
}`

const CheckoutForm = ({ setClientSecret, item }) => {
  const stripe = useStripe();
  const elements = useElements();
  // const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();
  const [finalizeOrder, { data, loading, error }] = useMutation(FINALIZE_ORDER)

  const handleSubmit = (e) => {
    e.preventDefault()
    // console.log(e.target.card_input.value)

    if (!stripe || !elements) return

    console.log({
      address: { line1: e.target.street.value, postalCode: e.target.postcode.value, townOrCity: e.target.town.value },
      email: e.target.email.value,
      items: [item.id],
      name: e.target.name.value,
    })

    finalizeOrder({
      variables: {
        address: { line1: e.target.street.value, postalCode: e.target.postcode.value, townOrCity: e.target.town.value },
        email: e.target.email.value,
        items: [item.id],
        name: e.target.name.value,
      }
    })

    // setLoading(true)

    // const { error } = await stripe.confirmPayment
  }

  useEffect(() => {
    if (!loading) console.log(data)
  }, [loading, data])

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'GB',
        currency: 'gbp',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then(result => {
        if (result) {
          // setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  if (loading) return 'Submitting...';
  if (error) return `Submission error! ${error.message}`;
  // Use a traditional checkout form.
  return (
    <div className="rounded-md w-full md:w-96 p-6 bg-standard-background bg-no-repeat bg-right bg-cover shadow-lg">

      {/* {paymentRequest && <><PaymentRequestButtonElement options={{ paymentRequest }} /> <br /> </>} */}

      <div className="flex-col space-y-2">
        <form className="space-y-4" onSubmit={(e) => { e.preventDefault(); handleSubmit(e) }}>
          <div className="flex flex-wrap">
            <div className="w-full">
              <input className="rounded-md w-full h-10 px-3 text-base p-5 placeholder-gray-500 shadow-sm" type="text" id="name" placeholder="Name" />
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <input className="rounded-md w-full h-10 px-3 text-base p-5 shadow-sm" type="text" id="street" placeholder="Address" />
            </div>
          </div>
          <div className="flex flex-wrap -mx-2 space-y-4 md:space-y-0">
            <div className="w-full px-2 md:w-1/2">
              <input className="rounded-md w-full h-10 px-3 text-base p-5 shadow-sm" type="text" id="town" placeholder="Town/City" />
            </div>
            <div className="w-full px-2 md:w-1/2">
              <input className="rounded-md w-full h-10 px-3 text-base p-5 shadow-sm" type="text" id="postcode" placeholder="Postcode" />
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <input className="rounded-md w-full h-10 px-3 text-base p-5 shadow-sm" type="text" id="email" placeholder="Email" />
            </div>
          </div>

          <CardElement className="rounded-md px-3 bg-white shadow-sm" id="card_input" options={{
            hidePostalCode: true,
            iconStyle: 'solid',
            style: {
              base: {
                lineHeight: '2.5rem',
                fontSize: '1.1em',
                color: 'gray',
                iconColor: 'gray',
                backgroundColor: 'white',
                borderRadius: '0.375rem',
                padding: '0.75rem',
              }
            }

          }} />
          <div className="flex space-x-4 items-center">
            <button type="submit" className="rounded-md px-4 py-2 font-extrabold text-white border-2 border-pink-600 bg-pink-600 hover:bg-purple-800 hover:border-purple-800 transition-colors">BUY</button>
            <p>Arrives in 2-3 working days</p>
          </div>
        </form>
      </div>
    </div>

  );
}

export default CheckoutForm