import React from 'react'
import ProductImage from '../components/ProductImage'
import ProductInfo from '../components/ProductInfo.js'

const Product = ({ item, setClientSecret }) => {
  return (
    <div className="flex flex-col mx-4 h-full w-full lg:flex-row lg:space-x-4">
      <div className="flex justify-center w-full lg:w-1/2 h-full">
        <div className="inset-0 w-full h-full">
          <ProductImage productImage={item.imageUrl} />
        </div>
      </div>
      <div className="flex-1 w-full lg:w-1/2 flex items-center justify-center container mx-auto">
        <div className="mx-auto h-full flex items-center">
          <ProductInfo item={item} setClientSecret={setClientSecret} />
        </div>
      </div>
    </div>
  )
}

export default Product