import SmallProduct from "./SmallProduct.js"

const onSmallProductClick = (item, setTopProduct) => {
    setTopProduct(item)
    window.scrollTo({ top: 0, behavior: "smooth" })
}

const SmallProductsGrid = ({ products, setTopProduct }) => {
    return (
        products.map(item => {
            return <SmallProduct item={item} onClick={() => onSmallProductClick(item, setTopProduct)} key={item.uniqueName} />
        })
    )
}

export default SmallProductsGrid