import React from 'react'

interface ProductImageProps {
    productImage: string
}

const ProductImage = ({productImage}: ProductImageProps) => {
    return (
        <img src={productImage} className="w-full h-full object-cover shadow-lg rounded-2xl transition-all" alt="Product"></img>
    )
}

export default ProductImage