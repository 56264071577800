import downArrow from '../images/keyboard_arrow_down_black_24dp.svg'

const handleClick = (smallProductsRef) => {
    smallProductsRef.current.scrollIntoView({behavior: "smooth"})
}

const ScrollDownArrow = ({ smallProductsRef }) => {
    return (
        <div onClick={() => handleClick(smallProductsRef)} className='items-center mt-auto mb-2'>
          <img className='hidden lg:block m-auto hover:cursor-pointer transform transition duration-500 hover:scale-150' src={downArrow} alt="Down Arrow" />
        </div>
    )
}

export default ScrollDownArrow