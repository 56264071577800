const SmallProduct = ({ item, onClick }) => {
    return (
        <div onClick={onClick} className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden transform transition duration-500 hover:cursor-pointer hover:scale-105 hover:shadow-xl">
            <div className="flex justify-center items-center h-64 overflow-hidden w-full">
                <img src={item.imageUrl} alt={item.name}/>
            </div>
            <div className="px-5 py-3">
                <h3 className="font-display text-xl font-bold">{item.name}</h3>
                <span className="text-sm font-mono">£{item.price}</span>
            </div>
        </div>
    )
}

export default SmallProduct