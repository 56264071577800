// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/free-solid-svg-icons'
// import React, { useState } from 'react'
import logo from '../images/logo-inline-light.png';
import smallLogo from '../images/logo-small-light.png'
import HelpPopup from './HelpPopup.js';

const Navbar = ({ helpOpen, setHelpOpen }) => {
  // const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <>
      {helpOpen && <HelpPopup isOpen={helpOpen} closeModal={() => setHelpOpen(false)} openModal={() => setHelpOpen(true)} />}
      <nav className="mb-4 bg-navbar-1 w-full">
        <div className="flex flex-row items-center p-6">
          <img className="hidden lg:block w-1/4" src={logo} alt="CSS Bristol" />
          <img className="lg:hidden w-1/4" src={smallLogo} alt="CSS Bristol" />
          <div className="ml-auto">
            <ul className="text-white border border-transparent hover:border-white uppercase font-mono font-bold hover:text-slate-400">
              <li>
                <button onClick={() => setHelpOpen(!helpOpen)}>
                  <p className="mx-1">Help</p>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
    // <>
    //   <nav className="relative flex items-center justify-between py-1 mb-3 z-50 bg-navbar-1 blur-md">
    //     <div className="container px-4 mx-auto flex items-center justify-between">
    //       <div className="w-full flex flex-row relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
    //         <a
    //           className="mr-4 py-2 items-center"
    //           href="#pablo"
    //         >
    //           <img src={logo} alt="CSS Bristol" className="w-1/4 h-full" />
    //         </a>
    //         <button
    //           className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
    //           type="button"
    //           onClick={() => setNavbarOpen(!navbarOpen)}
    //         >
    //           <FontAwesomeIcon icon={faBars} />
    //         </button>
    //         <div
    //           className={
    //             "lg:flex flex-grow items-center" +
    //             (navbarOpen ? " flex" : " hidden")
    //           }
    //           id="example-navbar-danger"
    //         >
    //           <ul className="flex lg:flex-row block list-none lg:ml-auto">
    //             <li className="nav-item">
    //               <a
    //                 className="px-3 py-2 flex items-center text-xs uppercase font-mono leading-snug hover:opacity-75"
    //                 href="#pablo"
    //               >
    //                 <span className="ml-2">Help</span>
    //               </a>
    //             </li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </nav>
    // </>
  );
}

export default Navbar