import React from 'react'
import CheckoutForm from './Checkout.js'

const ProductInfo = ({ item, setClientSecret }) => {
    return (
        <div className='justify-center'>
            <h1 className="font-display text-5xl font-extrabold uppercase tracking-wide">{item.name}</h1>
            <p className="text-lg font-display mb-4">By <span className="font-bold">{item.creator}</span></p>
            <div className="border-b-2"></div>
            <h2 className='text-2xl font-display font-bold uppercase my-4'>£{item.price}</h2>
            <div className="border-b-2"></div>
            <p className='font-lg font-mono mb-2 mt-4'>{item.description}</p>

            <CheckoutForm setClientSecret={setClientSecret} item={item} />

        </div>)
}

export default ProductInfo