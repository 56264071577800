import { Dialog, Transition } from '@headlessui/react'
import { Fragment, } from 'react'
import closeSVG from '../images/close_black_24dp.svg'

const HelpPopup = ({ openModal, closeModal, isOpen }) => {
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-500"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-500"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-500"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="bg-standard-background bg-no-repeat bg-right inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <div className="flex flex-row">
                                    <Dialog.Title
                                        as="h3"
                                        className="font-display font-bold text-3xl"
                                    >
                                        HELP
                                    </Dialog.Title>
                                    <button className="ml-auto self-start" onClick={closeModal}>
                                        <img alt="Close" class="w-5 h-5" src={closeSVG} />

                                    </button>

                                </div>
                                <div className="mt-2">
                                    <p className="text-md font-mono">
                                        Reach out to us at <a href="mailto:webmaster@cssbristol.co.uk">webmaster@cssbristol.co.uk</a>
                                    </p>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default HelpPopup