import React, { useEffect, useRef, useState } from 'react'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useQuery, gql } from '@apollo/client'

import Navbar from '../components/navbar.js'
import Product from './product.js'
import ScrollDownArrow from '../components/ScrollDownArrow.js'
import SmallProductsGrid from '../components/SmallProductsGrid.js'
import Loader from '../components/Loader.js'

const stripePromise = loadStripe('pk_test_51IqgKIBqcv3x0l1Q08tKVhYhV2Mf45s2G0hPFh5HjdmNSyo3mbOSVWfNhQWBzt5ex6hGApmAelQIfCQRBXMnwJjw00nQpHkVYO')

const ALL_ITEMS = gql`
query{allItems{
  creator
  description
  graphicUrl
  id
  imageUrl
  name
  price
  status
  uniqueName
  vendor
}}`

const Root = () => {
  const [clientSecret, setClientSecret] = useState()
  const { loading, error, data } = useQuery(ALL_ITEMS)
  const [topProduct, setTopProduct] = useState()
  const [helpOpen, setHelpOpen] = useState(false)
  const smallProductsRef = useRef(null)

  useEffect(() => {
    if (data) setTopProduct(data.allItems[0])
  }, [data])

  const options = {
    clientSecret
  }

  if (error) console.log(error)

  return (
    <Elements stripe={stripePromise} options={options}>
      <div className="flex flex-col mb-8">
        <div className='flex flex-col lg:h-screen' style={{ filter: helpOpen ? "blur(4px)" : "none" }}>
          <Navbar helpOpen={helpOpen} setHelpOpen={setHelpOpen} />
          {
            !loading && topProduct?
            <>
                <div className='w-full flex grow h-5/6 items-center justify-center'>
                  <Product item={topProduct} setClientSecret={setClientSecret} />
                </div>
                <ScrollDownArrow smallProductsRef={smallProductsRef} />
              </>
            :
            <Loader />
          }
        </div>
        {
          !loading &&
          <div ref={smallProductsRef} className="mx-6 grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 justify-center">
            <SmallProductsGrid products={data.allItems} setTopProduct={setTopProduct} />
          </div>
        }
      </div>
    </Elements>
  )

}

export default Root